import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { UserService } from '@app/core/users/user.service';
import { MessageService } from '@app/core/message/message.service';
import { NotificationHelper } from '@app/shared/helpers/notification.helper';
import { NotificationLoader } from '@app/core/notifications/notification.loader';

@Injectable()
export class PushMessagingService {
  private notificationLoader = inject(NotificationLoader);
  private messageService = inject(MessageService);
  private userService = inject(UserService);

  async initMessaging() {
    // todo: postMessage to FCM service worker
    initializeApp(environment.firebase);
    await NotificationHelper.requestPermission();

    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.vapidKey })
      .then((token) => {
        this.userService.updateRegistrationToken(token).subscribe({
          next: (res) => {
            console.log('user registration token updated', res);
          },
          error: (err) => {
            console.error('Unable to update user prefs', err);
          },
        });
      })
      .catch((err) => console.log('Error getting token', err));

    onMessage(messaging, (payload) => {
      this.notificationLoader.reloadNotifications();
      this.messageService.info(
        `Message received: ${payload.notification?.title}`,
      );
    });
  }
}
